import * as React from 'react';
import {FormHelper, ReactiveForm} from "../helpers/ReactiveForm";
import {FetchApi} from "../../FetchApi";
import {requestHelper} from '../helpers/requestHelper';

export const user = () => {
    if (localStorage.token) {
        try {
            let token = localStorage.token;
            token = token.substr(token.indexOf('.') + 1);
            token = token.substr(0, token.indexOf('.'));
            const padding = '='.repeat((4 - token.length % 4) % 4);
            token = (token + padding)
                .replace(/-/g, '+')
                .replace(/_/g, '/');
            token = atob(token);
            return JSON.parse(token);
        } catch (e) {
            console.log(e)
        }
    }
    return null;
}

export const Login = () => {

    const login = async (body) => {
        const response = await FetchApi.post<{token: string}>({
            path: 'login',
            body
        });

        if (response.token) {
            localStorage.token = response.token;
        }

        return response;
    }

    const [formRef, setFormRef] = React.useState<FormHelper>(null);

    return (<>
        <div className="d-flex flex-column mx-auto" style={{minHeight: '100vh', maxWidth: '1920px'}}>
            <div className="card m-auto col-lg-5 col-md-6 col-sm-8">
                <div className="card-body">
                    <div className="card-title text-center px-5">
                        <img src="" className={"img-fluid px-5"}/>
                    </div>
                </div>
                <div className="card-body">
                    <h4>تسجيل الدخول</h4>
                    <ReactiveForm valueAs="json" helperRef={form => setFormRef(form)} onSubmit={event =>
                        requestHelper({
                            form: event,
                            request: login(event.value),
                            redirectTo: {
                                success: '/'
                            }
                        })
                    }>
                        <div className="form-floating my-2">
                            <input dir="ltr" type="email" id="email" name="email" placeholder="fuc"
                                   className="form-control"/>
                            <label htmlFor="email">البريد الالكتروني</label>
                        </div>
                        <div className="form-floating my-2">
                            <input dir="ltr" type="password" id="password" name="password"  placeholder=""
                                   className="form-control"/>
                            <label htmlFor="password">كلمة المرور</label>
                        </div>
                        <div className="d-flex">
                            <button id="sign-in-button" type="submit" className="btn btn-primary my-2">تسجيل
                                الدخول
                            </button>
                        </div>
                    </ReactiveForm>
                </div>
            </div>
        </div>
    </>);
}
