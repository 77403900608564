import * as React from 'react';
import {FormHelper, ReactiveForm} from "../helpers/ReactiveForm";
import {requestHelper} from "../helpers/requestHelper";
import {FetchApi} from "../../FetchApi";
import {Loading} from "../helpers/Loading";
import {Link} from "./Link";


export interface LinkModel {
    id: string;
    key: string;
    value: string;
}

export interface AboutModel {
    id: number;
    description: string;
    links: LinkModel[];
    created_at: Date;
    updated_at: Date;
}

export interface AboutItem {
    msg: string;
    data: AboutModel;
}


export const About = () => {
    const [about, setAbout] = React.useState<AboutItem>();

    function retrieveAbout() {
        FetchApi.get<AboutItem>({
            path: `about/1`,
        }).then(m => {
            setAbout(m);
        })
    }

    function updateAbout(event: FormHelper) {
        requestHelper({
            form: event,
            request: FetchApi.patch({
                path: `about/1`,
                body: event.value,
            }).then(m => {
                retrieveAbout();
                return m;
            }),
        });
    }

    function createLink(event: FormHelper) {
        requestHelper({
            form: event,
            reset: {
                success: true,
            },
            request: FetchApi.post({
                path: `about/1/links`,
                body: event.value,
            }).then(m => {
                retrieveAbout();
                return m;
            }),
        });
    }

    function updateLink(event: FormHelper, id) {
        requestHelper({
            form: event,
            request: FetchApi.patch({
                path: `about/1/links/${id}`,
                body: event.value,
            }).then(m => {
                retrieveAbout();
                return m;
            }),
        });
    }

    function deleteLink(id) {
        requestHelper({
            request: FetchApi.delete({
                path: `about/1/links/${id}`,
            }).then(m => {
                retrieveAbout();
                return m;
            }),
        });
    }


    const textarea = React.useRef<HTMLTextAreaElement>();
    const autosizeTextarea = (element: HTMLTextAreaElement) => {
        element.style.height = `auto`;
        element.style.height = `${element.scrollHeight}px`;
        element.style.overflowY = `hidden`;
        element.style.resize = `none`;
    }

    React.useEffect(() => {
        if (textarea.current) {
            autosizeTextarea(textarea.current);
        }
    }, [textarea]);

    React.useEffect(() => {
        retrieveAbout();
    }, []);

    return <>
        <div className="d-flex p-2 p-md-4" style={{minHeight: '100vh'}}>
            <div className="my-auto w-100">
                <div className="d-flex">
                    <h2>الضبط</h2>
                </div>
                <Loading loading={about} render={() =>
                    <>
                        <ReactiveForm valueAs={"json"} onSubmit={updateAbout} className="card my-3">
                            <button className="btn btn-primary btn-lg btn-floating">
                                <i className="fas fa-save fa-fw" style={{transform: 'scaleX(-1)'}}/>
                            </button>
                            <div className="card-body">
                                <div className="col-12 my-3">
                                    <div className="form-floating">
                                <textarea name="description" placeholder="" rows={3} ref={textarea}
                                          defaultValue={about.data.description}
                                          className="form-control"
                                          onChange={event => autosizeTextarea(event.target)}/>
                                        <label>نبذة</label>
                                    </div>
                                </div>
                            </div>
                        </ReactiveForm>
                        <div className="card my-3">
                            <div className="card-body">
                                <h5> تواصل معنا</h5>
                                <ReactiveForm valueAs={"json"} onSubmit={event => createLink(event)} className="row">
                                    <div className="col-md-9 my-1">
                                        <Link defaultLink={''} defaultType={'phone'} linkName="value"
                                              typeName="key"/>
                                    </div>
                                    <div className="col-auto">
                                        <button className="btn btn-primary">اضافة</button>
                                    </div>
                                    <div className="col-12 mb-2"/>
                                </ReactiveForm>
                                <hr/>
                                {about.data.links.map(link => <>
                                    <ReactiveForm valueAs={"json"} onSubmit={event => updateLink(event, link.id)} className="row">
                                        <div className="col-md-9 my-1">
                                            <Link defaultLink={link.value} defaultType={link.key} linkName="value"
                                                  typeName="key"/>
                                        </div>
                                        <div className="col-auto d-md-flex flex-column">
                                            <button className="btn me-1 my-1 btn-primary">تحديث</button>
                                            <button type={"button"} onClick={() => deleteLink(link.id)} className="btn me-1 my-1 btn-danger">حذف</button>
                                        </div>
                                        <div className="col-12 mb-2"/>
                                    </ReactiveForm>
                                </>)}
                            </div>
                        </div>
                    </>
                }/>
            </div>
        </div>
    </>
}
