import * as React from 'react';
import {SermonItem} from "./interfaces";
import {setDebounce} from "../helpers/setDebounce";
import {FetchApi} from "../../FetchApi";
import {useParams} from 'react-router-dom';
import {FormHelper, ReactiveForm} from "../helpers/ReactiveForm";
import {PreacherPage} from "../preachers/interfaces";
import {Loading} from "../helpers/Loading";
import {BsEditor} from "../helpers/BsEditor";
import {requestHelper} from "../helpers/requestHelper";
import {decode} from 'html-entities';

export const ShowSermon = () => {
    const [sermon, setSermon] = React.useState<SermonItem>(null);
    const [preachers, setPreachers] = React.useState<PreacherPage>(null);

    const retrievePreachers = (body = {}) => {
        setDebounce(() => {
            FetchApi.get<PreacherPage>({
                path: 'preachers',
                body: {sorts: '-created_at', ...body},
            }).then(response => {
                setPreachers(response);
            })
        }, 'preachers', 500);
    }

    const {id} = useParams<{ id: string }>();

    const retrieveSermon = (id: string) => {
        setDebounce(() => {
            FetchApi.get<SermonItem>({
                path: `sermons/${id}`,
            }).then(response => {
                setSermon(response);
            })
        }, 'sermons', 500);
    }
    React.useEffect(() => {
        retrievePreachers({paginate: 1000000000000});
        retrieveSermon(id);
    }, [id]);

    function updateSermon(event: FormHelper) {
        requestHelper({
            form: event,
            request: FetchApi.patch({
                path: `sermons/${id}`,
                body: event.value,
            }).then(m => {
                retrieveSermon(id);
                return m;
            }),
        });
    }


    return <>
        <div className="d-flex p-2 p-md-4" style={{minHeight: '100vh'}}>
            <div className="my-auto w-100">
                <div className="d-flex">
                    <h2>تعديل خطبة</h2>
                </div>
                <Loading loading={sermon} render={() =>
                    <>
                        <ReactiveForm onSubmit={updateSermon} valueAs={"json"} className="card my-3">
                            <button className="btn btn-primary btn-lg btn-floating">
                                <i className="fas fa-save fa-fw"/>
                            </button>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input type="text" className="form-control" name="title" placeholder=""
                                                   defaultValue={sermon.data.title}/>
                                            <label>عنوان الخطبة</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <Loading loading={preachers} render={() =>
                                            <>
                                                <div className="form-floating">
                                                    <select className="form-control" name="preacher_id"
                                                            defaultValue={sermon.data.preacher.id}>
                                                        {preachers.data.map(preacher => <option key={preacher.id}
                                                                                                value={preacher.id}>{preacher.name}</option>)}

                                                    </select>
                                                    <label>الخطيب</label>
                                                </div>
                                            </>
                                        }/>
                                    </div>
                                </div>
                                <div className="col-12 my-3">
                                    <div className="form-floating">
                                        <BsEditor name="body" defaultValue={decode(sermon.data.body)}/>
                                        <label>المحتوى</label>
                                    </div>
                                </div>
                                <div className="col-12 my-3">
                                    <label className={"form-group p-3"}>
                                        <input type="checkbox" name="is_featured" className="slidebox"/>
                                        خطبة مميزة
                                    </label>
                                </div>
                            </div>

                        </ReactiveForm>
                    </>
                }/>
            </div>
        </div>
    </>
}
