import * as React from 'react';


export const Link = (
    {
        linkName,
        typeName,
        defaultLink,
        defaultType,
    }: {
        linkName: string;
        typeName: string;
        defaultLink: string,
        defaultType: string,
    }
) => {

    const [value, setValue] = React.useState(defaultLink);
    const [type, setType] = React.useState(defaultType);
    const [error, setError] = React.useState('');


    React.useEffect(() => {
        linkChange();
    }, [type]);

    const linksIcon = {
        phone: 'fas fa-phone',
        email: 'fas fa-envelope',
        website: 'fas fa-link',
        facebook: 'fab fa-facebook',
        twitter: 'fab fa-twitter',
        instagram: 'fab fa-instagram',
    };

    const linkChange = (): any => {
        if (!/facebook|twitter|instagram|website|whatsapp|phone|email/.test(type)) {
            setError('الرجاء اختيار منصة');
            return;
        }

        if (!value) {
            setError('');
            return;
        }
        let regex: RegExp;
        let replace;
        switch (type) {
            case 'facebook':
                // tslint:disable-next-line:max-line-length
                regex = /^(?:https?:\/\/)?(?:www\.|m\.|mobile\.|touch\.|mbasic\.)?(?:facebook\.com|fb(?:\.me|\.com))\/(?!$)(?:(?:\w)*#!\/)?(?:pages\/)?(?:photo\.php\?fbid=)?(?:[\w\-]*\/)*?(?:\/)?(?:profile\.php\?id=)?([^\/?&\s]*)(?:\/|&|\?)?.*$/;
                replace = 'https://facebook.com/$1';
                break;
            case 'twitter':
                regex = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/;
                replace = 'https://twitter.com/$1';
                break;
            case 'linkedin':
                break;
            case 'whatsapp':
                regex = /(\+?[0-9]*)/;
                replace = 'https://wa.me/$1';
                break;
            case 'phone':
                regex = /(\+?[0-9]*)/;
                replace = '$1';
                break;
            case 'instagram':
                regex = /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/im;
                replace = 'https://instagram.com/$1';
                break;
            default:
                regex = /(.*)/;
                replace = '$1';
        }

        const result = value.match(regex);

        if (!result || result.length < 2) {
            setError('الصيغة خاطئة');
            return;
        }

        console.log(value, type, error);

        setError('');
        setValue(replace.replace('$1', result[1]));
    }
    return <>
        <div className="form-floating">
            <input type="hidden" name={typeName} value={type}/>
            <input type="hidden" name={linkName} value={value}/>
            <input dir="ltr" type="text" className={`form-control ${error ? 'is-invalid' : ''}`}
                    placeholder="" value={value}
                   onBlur={() => linkChange()} onChange={event => setValue(event.target.value)}/>
            <label>رابط\الرقم</label>
        </div>
        <div className="btn-group my-1">
            {Object.entries((linksIcon)).map(([k, v]) => <>
                <button type="button" className={`btn btn-${type === k ? '' : 'outline-'}primary`} onClick={() => setType(k)}>
                    <i className={v}/>
                </button>
            </>)}
        </div>
        <br/>
        <div className="text-danger">{error}</div>
    </>
}
