import * as React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import {ListSermons} from "./ListSermons";
import {ShowSermon} from "./ShowSermon";
import {CreateSermon} from "./CreateSermon";

export const Sermons = () => {
    let { path, url } = useRouteMatch();
    return (<>
        <Switch>
            <Route path={`${path}`} exact={true}>
                <ListSermons/>
            </Route>

            <Route path={`${path}/add`} exact={true}>
                <CreateSermon/>
            </Route>

            <Route path={`${path}/:id`} >
                <ShowSermon/>
            </Route>

        </Switch>
    </>);
}
