import * as React from 'react';
import {Loading} from "../helpers/Loading";
import {FetchApi} from "../../FetchApi";
import {requestHelper} from "../helpers/requestHelper";
import {FormHelper, ReactiveForm} from "../helpers/ReactiveForm";
import {Modal} from 'bootstrap';
import {Pagination} from "../helpers/Pagination";
import {useQuery} from "../helpers/useQuery";
import {setDebounce} from "../helpers/setDebounce";
import {SermonModel, SermonPage} from "./interfaces";
import {Link, useHistory} from 'react-router-dom';
import {DateTime} from "luxon";


export const ListSermons = () => {

    const [sermons, setSermons] = React.useState<SermonPage>(null);
    const [query, setQuery] = useQuery();
    const history = useHistory();

    const retrieveSermons = (body = {}) => {
        setDebounce(() => {
            FetchApi.get<SermonPage>({
                path: 'sermons',
                body: {sorts: '-created_at', ...body},
            }).then(response => {
                setSermons(response);
            })
        }, 'sermons', 500);
    }

    const toggleHandler = (sermon: SermonModel) => {
        requestHelper({
            request: FetchApi.patch({
                path: `sermons/${sermon.id}/toggle`,
            }).then(response => {
                retrieveSermons(query);
                return response;
            }),
        })
    }

    React.useEffect(() => {
        retrieveSermons(query);
    }, [query]);

    const filterChange = (event: FormHelper) => {
        setQuery(event.value, 'merge');
    }

    return <>
        <Link className="btn btn-primary btn-lg btn-floating" to="/sermons/add">
            <i className="fas fa-plus fa-fw"/>
        </Link>
        <div className="d-flex p-2 p-md-4" style={{minHeight: '100vh'}}>
            <div className="my-auto w-100">
                <div className="d-flex">
                    <h2>الخطب</h2>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ReactiveForm valueAs="json" onChange={filterChange}>
                            <input type="hidden" name="page" value={1}/>
                            <div className="row">
                                <div className="col-md-4 col-sm-6 col-xl-3 my-3">
                                    <div className="form-floating">
                                        <input type="text" id="q" name="q" className="form-control" placeholder=""
                                               defaultValue={query['q']}/>
                                        <label htmlFor="q">بحث</label>
                                    </div>
                                </div>
                            </div>
                        </ReactiveForm>
                        <hr/>
                    </div>
                    <Loading loading={sermons} render={() =>
                        <>
                            <div className="col-12 text-center">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-hover">
                                                <thead>
                                                <tr>
                                                    <th className="border-primary border-top-0 border-start-0 border-end-0 border-2"
                                                        scope="col">#
                                                    </th>
                                                    <th className="border-primary border-top-0 border-start-0 border-end-0 border-2"
                                                        scope="col">عنوان الخطبة
                                                    </th>
                                                    <th className="border-primary border-top-0 border-start-0 border-end-0 border-2"
                                                        scope="col">اسم الخطيب
                                                    </th>
                                                    <th className="border-primary border-top-0 border-start-0 border-end-0 border-2"
                                                        scope="col">وقت الانشاء
                                                    </th>
                                                    <th className="border-primary border-top-0 border-start-0 border-end-0 border-2"
                                                        scope="col">اخر تعديل
                                                    </th>
                                                    <th className="border-primary border-top-0 border-start-0 border-end-0 border-2"
                                                        scope="col">الحالة
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {sermons.data.map((sermon, index) =>
                                                    <tr key={sermon.id} onClick={() => history.push(`/sermons/${sermon.id}`)}>
                                                        <td className="border-primary py-3">{index + (sermons.meta.per_page * sermons.meta.current_page)}</td>
                                                        <td className="border-primary py-3">{sermon.title}</td>
                                                        <td className="border-primary py-3">{sermon.preacher.name}</td>
                                                        <td className="border-primary py-3">{DateTime.fromISO(sermon.created_at).toLocaleString(DateTime.DATETIME_MED)}</td>
                                                        <td className="border-primary py-3">{DateTime.fromISO(sermon.updated_at).toLocaleString(DateTime.DATETIME_MED)}</td>
                                                        <td className="border-primary py-3">
                                                            <input type="checkbox" className="slidebox inverted"
                                                                   readOnly={true}
                                                                   checked={sermon.is_disabled}
                                                                   onClickCapture={event => {
                                                                       event.nativeEvent.stopImmediatePropagation();
                                                                       toggleHandler(sermon);
                                                                   }}/>
                                                        </td>
                                                    </tr>
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 my-3">
                                <Pagination pagination={sermons.meta}/>
                            </div>
                        </>
                    }/>
                </div>
            </div>
        </div>
    </>
}
