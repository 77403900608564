import * as React from 'react';
import {environment} from "../environment";
import 'bootstrap';
import {BrowserRouter as Router, Redirect, Route, Switch, useHistory,} from "react-router-dom";

import {Login} from "./modules/auth/Login";
import {Dashboard, DashboardLink} from "./modules/dashboard/Dashboard";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Home} from "./modules/home/Home";
import {Preachers} from "./modules/preachers/Preachers";
import {Sermons} from "./modules/sermons/Sermons";

import {Settings} from 'luxon'
import {Users} from "./modules/users/Users";
import {Notifications} from "./modules/notifications/Notifications";
import {About} from "./modules/about/About";
import {FetchApi} from "./FetchApi";

Settings.defaultLocale = 'ar';
Settings.defaultNumberingSystem = 'latn';

const links: DashboardLink[] = [
    {
        path: '',
        label: 'الرئيسية',
        icon: 'fas fa-home fa-fw',
    },
    {
        path: '/preachers',
        label: 'الخطباء',
        icon: 'fas fa-user-edit fa-fw',
    },
    {
        path: '/sermons',
        label: 'الخطب',
        icon: 'fas fa-scroll fa-fw',
    },
    {
        path: '/users',
        label: 'المستخدمين',
        icon: 'fas fa-users fa-fw',
    },
    {
        path: '/notifications',
        label: 'الاشعارات',
        icon: 'fas fa-bell fa-fw',
    },
    {
        path: '/about',
        label: 'الضبط',
        icon: 'fas fa-cog fa-fw',
    },
];

export const App = () => {
    const logout = () => {

    }
    return <>
        <ToastContainer/>
        <Router>
            <Switch>
                <Route path={"/login"}>
                    {!localStorage.token ? <Login/> : <Redirect to="/"/>}
                </Route>
                <Route path={"/logout"} render={() => {
                    localStorage.removeItem('token');
                    window.location.href = "/login";
                    return <></>;
                }}/>
                <Route path={"/"}>
                    {!localStorage.token ? <Redirect to="/login"/> : <>
                        <Dashboard links={links}>
                            <Switch>
                                <Route path={"/"} exact={true}>
                                    <Home/>
                                </Route>

                                <Route path={"/preachers"}>
                                    <Preachers/>
                                </Route>

                                <Route path={"/sermons"}>
                                    <Sermons/>
                                </Route>

                                <Route path={"/users"}>
                                    <Users/>
                                </Route>

                                <Route path={"/notifications"}>
                                    <Notifications/>
                                </Route>

                                <Route path={"/about"}>
                                    <About/>
                                </Route>
                            </Switch>
                        </Dashboard>
                    </>}
                </Route>
            </Switch>
        </Router>
    </>
}


FetchApi.servers = {
    primary: {
        baseUrl: environment.backend,
        versions: {
            1: 'api/v1/',
        },
        defaultVersion: 1,
    }
}

FetchApi.defaultServer = 'primary';


const filterRegex = /^\??filter\[([\d\w_\-.]+)]/g;
FetchApi.interceptors.request = (request, next) => {

    request.headers = (() => {
        console.log(Object.entries(request.headers || {}));
        const headers = {};
        for (const [key, value] of Object.entries(new Headers(request.headers))) {
            const k = key.replace(/(\w+)/g, (r: string) => r.charAt(0).toUpperCase() + r.substr(0));
            console.log(k);
            headers[k] = value;
        }
        return headers;
    })()

    if (localStorage.token) {
        request.headers['Authorization'] = `bearer ${localStorage.token}`
        request.path = 'admin/' + request.path;
    }

    if (request.method === 'GET' && request.body) {
        request.body = Object.entries(request.body).reduce((acc, [key, value]) => {
            if (value === undefined) {
                return acc;
            }
            key = decodeURIComponent(key);
            const filter = filterRegex.exec(key);
            if (filter) {
                acc.filter = (acc.filter || '');
                // console.log(filter);
                acc.filter += `${filter[1]}: ${value}`;
            } else {
                acc[key] = value;
            }
            return acc;
        }, {} as any);
    } else if (request.method !== "POST" && request.body instanceof FormData) {
        console.log(request.method);
        request.body.append('_method', request.method);
        request.method = 'POST';
    }

    if (request.body && request.method !== "GET" && !request.headers['Content-Type']) {
        if (request.body instanceof FormData) {
            request.headers['Content-Type'] = 'multipart/form-part';
        } else if (typeof request.body === 'string') {
            try {
                JSON.parse(request.body);
                request.headers['Content-Type'] = 'application/json';
            } catch (e) {}
        } else if (typeof request.body === 'object') {
            try {
                // request.body = JSON.stringify(request.body);
                request.headers['Content-Type'] = 'application/json';
            } catch (e) {}
        }
    }
    return next(request);
}

FetchApi.interceptors.response = async (response, request, next) => {

    const headers = new Headers(response.headers);

    const res = await next(response);

    if (headers.get('Content-Type') === 'application/json') {

        if (response.status >= 300 && !res?.code) {
            res.code = 3000;
        }

        if (res.code >= 3000) {
            throw res;
        }
    }
    return res;
}
