import * as React from 'react';
import {Link, useHistory} from 'react-router-dom';
import {user} from "../auth/Login";

import tippy from "tippy.js";
import 'tippy.js/dist/tippy.css';
import {useEffect} from "react";

export interface DashboardLink {
    path: string;
    label: string;
    icon: string;
}

export const Dashboard = ({children, links = []}: { children?; links?: DashboardLink[] }) => {

    const [primary, setPrimary] = React.useState('');
    const history = useHistory();
    const dashboard = React.useRef<HTMLElement>();

    useEffect(() => {
        history.listen((location, action) => {
            dashboard.current?.setAttribute('open', 'false');
        })
    }, [history]);


    React.useEffect(() => {
        const bodyStyles = window.getComputedStyle(document.body);
        setPrimary(bodyStyles.getPropertyValue('--bs-primary'));
        dashboard.current.addEventListener('collapseChange', event => {
            // @ts-ignore
            localStorage.collapse = event.detail
        });

        tippy('#userMenuToggle', {
            content: document.getElementById('userMenu'),
            allowHTML: true,
            trigger: 'click',
            placement: 'auto',
            interactive: true,
            appendTo: document.body,
            maxWidth: '100%',
        });

        tippy('[data-tippy-content]', {
            onTrigger(instance, event) {

                instance.popper.style.minWidth = instance.reference.clientWidth + 'px !important';
                instance.popper.classList.add('d-none');
                if (localStorage.collapse === 'true') {
                    instance.popper.classList.add('d-md-block');
                } else {
                    instance.popper.classList.remove('d-md-block');
                }
            }
        });
    }, [])

    return <>
        {/* @ts-ignore */}
        <collapsible-dashboard ref={dashboard}
                               collapse={localStorage.collapse}
                               side-panel-bg-color={primary}
                               mobile-nav-color={primary}
                               mobile-nav-bg-color="#f5f5f5">
            <div slot="side-panel-header">
                <svg viewBox="0 0 100 75" style={{maxWidth: '100px'}}>
                    <text x="50%" y="50%" fill="#232323" fontSize="30px" textAnchor="middle">&nbsp;الخطيب&nbsp;</text>
                </svg>
            </div>
            <div slot="side-panel-content">
                {/* @ts-ignore */}
                <dashboard-nav id="userMenuToggle" className="clickable">
                    <i slot="icon" className="fas fa-user-cog"/>
                    {user()?.name || user()?.role}
                    {/* @ts-ignore */}
                </dashboard-nav>
                <div id="userMenu" className="d-flex flex-column">
                    <div className="btn btn-outline-light border-0">
                        <i slot="icon" className="fas fa-user-cog me-1"/>
                        {user()?.name || user()?.role}
                    </div>
                    <hr className="dropdown-divider"/>
                    <a className="btn btn-outline-light border-0" href="/logout">
                        <i className="my-1 me-2 fas fa-sign-out-alt fa-fw"/>
                        تسجيل الخروج
                    </a>
                </div>
                {links.map((link, index) =>
                    <Link key={link.path} to={link.path} style={{color: 'inherit', textDecoration: 'none'}}>
                        {/* @ts-ignore */}
                        <dashboard-nav data-tippy-content={link.label} data-tippy-placement="auto-end">
                            <i slot="icon" className={link.icon}/>
                            {link.label}
                            {/* @ts-ignore */}
                        </dashboard-nav>
                    </Link>
                )}
            </div>
            <div className="container-fluid container-lg">
                {children}
                <div className="py-5"/>
            </div>
            {/* @ts-ignore */}
        </collapsible-dashboard>
    </>;
}
