import * as React from 'react';
import {Route, Switch, useRouteMatch} from "react-router-dom";
import {ListUsers} from "./ListUsers";


export const Users = () => {
    let { path, url } = useRouteMatch();
    return (<>
        <Switch>
            <Route path={`${path}`} exact={true}>
                <ListUsers/>
            </Route>

        </Switch>
    </>);
}
