import * as React from 'react';
import {Loading} from "../helpers/Loading";
import {FetchApi} from "../../FetchApi";
import {requestHelper} from "../helpers/requestHelper";
import {FormHelper, ReactiveForm} from "../helpers/ReactiveForm";
import {useQuery} from "../helpers/useQuery";
import {setDebounce} from "../helpers/setDebounce";
import {UserModel, UserPage} from "./interfaces";
import {Link, useHistory} from 'react-router-dom';
import {DateTime} from "luxon";
import {Pagination} from "../helpers/Pagination";


export const ListUsers = () => {

    const [users, setUsers] = React.useState<UserPage>(null);
    const [query, setQuery] = useQuery();
    const history = useHistory();

    const retrieveUsers = (body = {}) => {
        setDebounce(() => {
            FetchApi.get<UserPage>({
                path: 'users',
                body: {sorts: '-created_at', ...body},
            }).then(response => {
                setUsers(response);
            })
        }, 'users', 500);
    }

    const toggleHandler = (user: UserModel) => {
        requestHelper({
            request: FetchApi.patch({
                path: `users/${user.id}/toggle`,
            }).then(response => {
                retrieveUsers(query);
                return response;
            }),
        })
    }

    React.useEffect(() => {
        retrieveUsers(query);
    }, [query]);

    const filterChange = (event: FormHelper) => {
        setQuery(event.value, 'merge');
    }

    return <>
        <Link className="btn btn-primary btn-lg btn-floating" to="/users/add">
            <i className="fas fa-plus fa-fw"/>
        </Link>
        <div className="d-flex p-2 p-md-4" style={{minHeight: '100vh'}}>
            <div className="my-auto w-100">
                <div className="d-flex">
                    <h2>المستخدمين</h2>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ReactiveForm valueAs="json" onChange={filterChange}>
                            <input type="hidden" name="page" value={1}/>
                            <div className="row">
                                <div className="col-md-4 col-sm-6 col-xl-3 my-3">
                                    <div className="form-floating">
                                        <input type="text" id="q" name="q" className="form-control" placeholder=""
                                               defaultValue={query['q']}/>
                                        <label htmlFor="q">بحث</label>
                                    </div>
                                </div>
                            </div>
                        </ReactiveForm>
                        <hr/>
                    </div>
                    <Loading loading={users} render={() =>
                        <>
                            <div className="col-12 text-center">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-hover">
                                                <thead>
                                                <tr>
                                                    <th className="border-primary border-top-0 border-start-0 border-end-0 border-2"
                                                        scope="col">#
                                                    </th>
                                                    <th className="border-primary border-top-0 border-start-0 border-end-0 border-2"
                                                        scope="col">اسم المستخدم
                                                    </th>
                                                    <th className="border-primary border-top-0 border-start-0 border-end-0 border-2"
                                                        scope="col">رقم الهاتف
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {users.data.map((user, index) =>
                                                    <>
                                                        <tr>
                                                            <td className="border-primary py-3">{index + (users.meta.per_page * users.meta.current_page)}</td>
                                                            <td className="border-primary py-3">{user.name}</td>
                                                            <td className="border-primary py-3" dir="ltr">{user.phone}</td>
                                                        </tr>
                                                    </>
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 my-3">
                                <Pagination pagination={users.meta}/>
                            </div>
                        </>
                    }/>
                </div>
            </div>
        </div>
    </>
}
