export const environment =
    process.env.NODE_ENV === 'development' ?
        {
            production: false,
            backend: 'https://sadeem-veil.com/~alkateeb/public/'
        }
        :
        {
            production: true,
            backend: 'https://sadeem-helix.com/~alkateeb/public/'
        }
