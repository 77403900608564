import * as React from 'react';
import {FormHelper, ReactiveForm} from "../helpers/ReactiveForm";
import {requestHelper} from "../helpers/requestHelper";
import {FetchApi} from "../../FetchApi";
import {SermonItem} from "../sermons/interfaces";

export const Notifications = () => {

    function createNotification(event: FormHelper) {
        requestHelper({
            form: event,
            reset: {success: true},
            request: FetchApi.post<SermonItem>({
                path: `users/notify`,
                body: event.value,
            }).then(m => {
                return m;
            }),
        });
    }

    const textarea = React.useRef<HTMLTextAreaElement>();
    const autosizeTextarea = (element: HTMLTextAreaElement) => {
        element.style.height = `auto`;
        element.style.height = `${element.scrollHeight}px`;
        element.style.overflowY = `hidden`;
        element.style.resize = `none`;
    }

    React.useEffect(() => {
        if (textarea.current) {
            autosizeTextarea(textarea.current);
        }
    }, [textarea]);

    return <>
        <div className="d-flex p-2 p-md-4" style={{minHeight: '100vh'}}>
            <div className="my-auto w-100">
                <div className="d-flex">
                    <h2>ارسال إشعار</h2>
                </div>
                <ReactiveForm valueAs="json" onSubmit={createNotification} className="card my-3">
                    <button className="btn btn-primary btn-lg btn-floating">
                        <i className="fas fa-play fa-fw" style={{transform: 'scaleX(-1)'}}/>
                    </button>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-floating">
                                    <input type="text" className="form-control" name="title" placeholder=""
                                    />
                                    <label>عنوان</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 my-3">
                            <div className="form-floating">
                                <textarea name="body" placeholder="" rows={3} ref={textarea} className="form-control"
                                          onChange={event => autosizeTextarea(event.target)}/>
                                <label>المحتوى</label>
                            </div>
                        </div>
                    </div>
                    <input type="hidden" name="topic" value={"all"}/>
                </ReactiveForm>

            </div>
        </div>
    </>
}
